.questions {
   // background-color: #1d2025;
    background-image: url("../../img/bkg/ODocOQFdUo3m71i_2.png");
  color: white;
 // height: 700px;
  background-size: cover;
}


.questions-box {
  margin-top: 150px;
  margin-bottom: 50px;
}

.accordion {

  transform: translateZ(0);
  background: #1d2025;
  border-bottom: 1px solid #c91e31;
  border-left: 1px solid #c91e31;
  border-right: 1px solid #c91e31;
 
}

.accordion>.accordion-toggle {
  display: none;
  position: absolute;
  opacity: 0;
}

.accordion>label {
  display: block;
  position: relative;
  height: 50px;
  line-height: 50px;
  padding: 0 20px;
  border-top: 1px solid #c91e31;
  border-bottom: 1px solid #c91e31;

  background: #1d2025;
  color: white;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
}

.accordion>label:after {
  content: '';
  position: absolute;
  top: 12px;
  right: 20px;
  width: 12px;
  height: 12px;
  border-left: 2px solid #c91e31;
  border-top: 2px solid #c91e31;
  transform: rotate(-135deg);
  transition: 0.4s transform;
}

.accordion>section {
  overflow: hidden;
  height: 0;
  transition: all .4s;
}

.accordion>.accordion-toggle:checked~label:after {
  transform: rotate(45deg);
  top: 16px;
}

.accordion>.accordion-toggle:checked~section {
  height: 100px;
}

.accordion>section p {
  margin: 15px 0;
  padding: 0 20px;
}

.mobile_visible_qestion {
  display: none;
}


@media (max-width: 450px) {

.mobile_hide_qestion {
  display: none;
}

.mobile_visible_qestion {
  display: inline;
}

.accordion>.accordion-toggle:checked~section {
  height: 200px;
}

}
