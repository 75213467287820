.home-page {
  background-image: url("../img/bkg/vw_audi.jpg"); /* Путь к фоновому изображению */
  background-color: #000000; /* Цвет фона */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: block;
  box-shadow: 0 0 10px rgba(0,0,0,1);
}


.menu {
  background-color: rgba(0,0,0,.1);
  width: 100%;
  position: fixed;
  z-index: 10;
  height: 60px;
}
.logo {
  //width: 58px;
  //height: 58px;
  //padding-top: 11px;
  //font-family: Trebuchet MS, sans-serif;
  //line-height: 1.2;
  line-height: 58px;
  a {
  text-decoration: none;
  color: black;
  }
  img {
    width: 400px;

  }
}
.menu_wrapper {
  display: flex;
  justify-content: space-between;
  width: 1400px;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
}

.menu_href_wrapper {
  display: flex;
  line-height: 58px;
  justify-content: space-between;
  width: 500px;
  font-family: Verdana, sans-serif;
  font-size: 14px;

  a{
  //width: 90px;
  text-align: center;
  font-weight: 600;
  color: #d5d5d7;
  text-decoration: none;
  -webkit-transition: 0.1s ease-in;
  -o-transition: 0.1s ease-in;
  transition: 0.1s ease-in;
  }

  a:hover,
  a:focus {
  color: rgba(195, 195, 195, 0.7);
  outline: none;
  }
  a:active{
  color: rgba(100, 100, 100, 1);
  outline: none;
  }

}

.menu_contacts {
  font-family: Verdana, sans-serif;
  font-size: 14px;
  line-height: 30px;
  a{
  width: 90px;
  text-align: center;
  font-weight: 600;
  color: #d5d5d7;
  text-decoration: none;
  -webkit-transition: 0.1s ease-in;
  -o-transition: 0.1s ease-in;
  transition: 0.1s ease-in;
  }

  a:hover,
  a:focus {
  color: rgba(195, 195, 195, 0.7);
  outline: none;
  }
  a:active{
  color: rgba(100, 100, 100, 1);
  outline: none;
  }
}

.home_about_box {
display: flex;
width: 450px;
justify-content: space-between;
margin-top: 30px;
}

.home_about_item {
  text-align: center;
  font-weight: 600;
  color: rgba(255,255,255,.8);
}

.home_about_item img {
  width: 50px;
  height: 50px;
}


.home-heading  {
  margin-top: 140px;
  color: white;
 }

 .home-general-heading {
  font-size: 60px;
  width: 440px;
  font-weight: 900;
  line-height: 1.2;
  font-family: Verdana, sans-serif;
 }

.home-secondary-heading {
  margin-top: 15px;
  font-size: 14px;
  width: 450px;
  line-height: 1.2;
  font-family: Arial, sans-serif;
}

@media (max-width: 800px) {

.menu {
  height: 150px;
}
.menu_wrapper {
  display: unset;
  text-align: center;
  width: 100%;
}
.menu_href_wrapper {
  display: unset;
  line-height: 10px;
  
  a{margin-left: 20px;}
  }
.menu_contacts { 
  text-align: right;
  margin-right: 10px;

}
.logo {
  img {
    width: 300px;
  }
}
.home_about_box {
width: 450px;
margin-top: 250px;
margin-left: 50%;
transform: translateX(-50%);
}

.home-secondary-heading {
  margin-top: 15px;
 // background-color: rgba(0, 0, 0, .6);
  width: auto;
  margin-left: 15px;
  margin-right: 15px;
}
.home-page {
  .container{
    padding-top: 2px;
  }
}
}

@media (max-width: 450px) {

.home-heading  {
  margin-top: 140px;
 }

 .home-general-heading {
  font-size: 25px;
  margin: 15px;
  width: auto;
 }

  .menu {
  height: 120px;
}
.menu_contacts { 
  display: none;
}
.logo {
  img {
    width: 250px;
  }
}
.menu_href_wrapper a {
  font-size: 12px;
}

.home_about_box {
width: 320px;
margin-top: 30px;
margin-left: 50%;
transform: translateX(-50%);
}
}

