body 	{
	
	background: #FFFFFF;
	//background: #EC2424;
	font-family: sans-serif;

} 

.general-container {
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	
}

.container {
	max-width: 1400px;
	margin-left: auto;
	margin-right: auto;
	height: 970px;
	padding-top: 40px;	
}

.general-heading {
	text-align: center;
	font-size: 40px;
	line-height: 1.2;
	font-family: Verdana, sans-serif;
	margin-bottom: 20px;
	font-weight: 600;
	p:first-letter {
		color: #c91e31;
	}
}


.secondary-heading {
	text-align: center;
	
	font-weight: 300;
	line-height: 1.2;
	font-family: Arial, sans-serif;
}
