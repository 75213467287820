.messengers_buttons {
	display: none;
	
	position: fixed;
	right: 15%;
	bottom: 25px;
	//margin-top: 30px;
	z-index: 10;
	a {
		//margin-left: 50px;

	}
	img { 
		width: 45px;
		height: 45px;
	 }

	.whatsapp_button {
		background-color: #33b446;
		width: 60px;
		height: 60px;
		margin-right: 20px;
		border-radius: 50px;
		padding: 8px;
	}
	.telegram_button {
		background-color: #27a7e5;
		width: 60px;
		height: 60px;
		margin-right: 50px;
		border-radius: 50px;
		
		padding-left: 4px;
		padding-top: 9px;
	}
}

@media (max-width: 800px) {

.messengers_buttons {

display: flex;

}


}