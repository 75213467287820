.contact {
	background-color: #1d2025;
	color: white;


	.container {
		height: 800px;
	}
}

.contact-item-box {
	display: flex;
 	align-items: center;
 	justify-content: space-between; 
 	
	

}

.contacts-text_border {
	height: 447px;
	width: 667px;
	border-color: #c91e31;
	border-style: solid;
	border-width: 2px;

}

.contacts-text {
	margin-top: 50%;
	transform: translateY(-70%);
	
	margin-left: 50px;
	display: flex;
	flex-wrap: wrap;
	width: 450px;
	div {
		margin-bottom: 50px;
	}
	a{
  	color: white;
 	text-decoration: none;
  	-webkit-transition: 0.1s ease-in;
  	-o-transition: 0.1s ease-in;
  	transition: 0.1s ease-in;
  	}

  	a:hover,
 	a:focus {
  	color: #c91e31;
  	outline: none;
  	}
 	a:active{
  	color: #CB3A44;
  	outline: none;
  	}
}



.contacts-text_social-media {
	img:hover, img:focus  {
    transform: scale(1.3); /* Увеличиваем масштаб */
   } }

.contacts-text_social-media img:active  {
    transform: scale(1); /* Увеличиваем масштаб */
 }





.contacts-text_phone, .contacts-text_social-media  {
		//margin: 40px;
		margin-left: 72px; 
	}

 .contacts-map_big {
 display: block;
}
 .contacts-map_little {
 display: none;
}

.social_web_block {
	img {
		width: 30px;
		height: 30px;
		margin-right: 10px;
		transition: .5s;
	}
}

@media (max-width: 800px) {

.contacts-text_border {
	height: 100px;
	width: 300px;
	border-style: none;
}

.contacts-text {
	top: 50%;
	transform: translateY(-35%);
	}

 .contacts-map_big {
 display: none;
}
 .contacts-map_little {
 	margin-top: 150px;
 display: block;
 
 position: absolute;
 left: 50%;
 transform: translateX(-50%);
}
.contact {
	.container {
		height: 800px;
	}
}

.contact-item-box {
	display: block;
 	align-items: center;
 	justify-content: normal; 
}

.contacts-text {
width: 300px;
div {
		margin-bottom: 10px;
	}
}
.contacts-text_phone, .contacts-text_social-media  {
		//margin: 40px;
		margin-left: 0px; 
	}

}