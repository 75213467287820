.services {

	background-color: #1d2025;
	color: white;
} 


.service_box--wrapper {
	display: flex;
	justify-content: space-between;
	margin-top: 90px;
}


.service_flag__wrapper {
  position: relative;
}

.service_flag {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  img {
    width: 862px;
    opacity: 50%;
  }
}

.service_box {
	width: 300px;

	//text-align: center;

	a {
  text-decoration: none;
  color: white;
  }
  background-color: #c92132;
}

.service_box:hover .img_service_box img {
    transform: scale(.9); /* Увеличиваем масштаб */
   }

.img_service_box {
	width: 300px;
	height: 250px;
	display: inline-block; /* Строчно-блочный элемент */
    overflow: hidden; /* Скрываем всё за контуром */
    position: relative;

  img {
  	position: absolute;
  	margin: auto;
  	left: -100%;
  	right:-100%;
	top: -100%;
  	bottom: -100%;
  	transition: 1s; /* Время эффекта */
    display: block; /* Убираем небольшой отступ снизу */
   }

}

.text_box_service_box {
	padding-left: 15px;
 height: 150px;
 width: 300px;
 margin-top: -7px;
 background-color: #c92132;
 position: relative;
 h2{
 	padding-top: 10px;
 }
}


.btn_service_box {
	position: absolute;
  bottom: 32px;
  //margin-top: 20px;
  left: 50%;
  transform: translateX(-50%);
 border-color: white;
 border: solid;
	border-width: 1px;

 display: inline-block;
padding: 10px;
}


#btn_service_box {
  bottom: 15px;
}

#registration {
    background-color: #373C45;
    color: white;
}

.animated-modal {
  max-width: 550px;
  border-radius: 4px;
  overflow: hidden;
  
  transform: translateY(-50px);
  transition: all .9s;
}

/* Final state */
.fancybox-slide--current .animated-modal {
  transform: translateY(0);
  opacity: 1;
}

#msg {
  //background-color: #93d3a2;
  position: fixed;
  bottom: 20px;
  z-index: 99999;
  left: 50%;
  transform: translateX(-50%);
  color: #373C45;
  border-style: solid;
  border-radius: 5px;
  border-width: 2px;
  border-color: rgba(0,0,0,0);
  padding: 10px;
  transition: 2s;
  opacity: 0;
  width: 200px;
}


@media (max-width: 800px) {

.services {
height: 1900px;
}
.service_box--wrapper {
  display: unset;
  position: absolute;
  margin-top:10px;
  margin-left: 50%;
  transform: translateX(-50%);
  
}

.service_box {
margin-bottom: 20px;
}

.service_flag__wrapper {
  display: none;
}

}





//форма для обратной связи


.form-inner {padding: 5px; line-height: 5px;}
.form-inner input,
.form-inner textarea {
  display: block;
  width: 100%;
  padding: 0 20px;
  margin-bottom: 25px;
  background: #E9EFF6;
  line-height: 30px;
  border-width: 0;
  border-radius: 20px;
  font-family: 'Roboto', sans-serif;
}
.form-inner input[type="submit"] {
  margin-top: 20px;
  background: #f69a73;
  border-bottom: 4px;
  color: white;
  font-size: 14px;
  
}

#submit:hover,
  #submit:focus {
  background-color: rgba(180, 180, 180, 0.7);
  outline: none;
  }
  #submit:active{
  background-color: rgba(100, 100, 100, 1);
  outline: none;
  }


.form-inner textarea {resize: none;}
.form-inner h3 {
  margin-top: 0;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 24px;
  color: #707981;
}