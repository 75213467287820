body {
  background: #FFFFFF;
  font-family: sans-serif;
}
.general-container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.container {
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  height: 970px;
  padding-top: 40px;
}
.general-heading {
  text-align: center;
  font-size: 40px;
  line-height: 1.2;
  font-family: Verdana, sans-serif;
  margin-bottom: 20px;
  font-weight: 600;
}
.general-heading p:first-letter {
  color: #c91e31;
}
.secondary-heading {
  text-align: center;
  font-weight: 300;
  line-height: 1.2;
  font-family: Arial, sans-serif;
}
.cookie_warning {
  position: fixed;
  bottom: 15px;
  left: 50px;
  width: 350px;
  font-size: 70%;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  z-index: 5;
  background-color: #1d2025;
  padding: 10px;
  visibility: hidden;
  color: white;
}
@media (max-width: 940px) {
  .cookie_warning {
    bottom: 10px;
    left: 5px;
    width: 150px;
    z-index: 9999999;
  }
}
.cookie_btn_text {
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  margin-top: 5px;
  border-radius: 5px;
  border-style: solid;
  border-width: 2.5px;
  width: 100px;
  height: 25px;
  color: #c91e31;
  position: relative;
  background-color: #c91e31;
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
}
.cookie_btn_text p {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  font-size: 12px;
  font-family: 'Open sans', sans-serif;
  font-weight: 600;
  text-align: center;
  color: #ffffff;
}
.cookie_btn_text:hover,
.cookie_btn_text:focus {
  background-color: #DD263A;
  color: #DD263A;
  outline: none;
}
.cookie_btn_text:active {
  background-color: #6C0E18;
  color: #6C0E18;
}
.home-page {
  background-image: url("../img/bkg/vw_audi.jpg");
  /* Путь к фоновому изображению */
  background-color: #000000;
  /* Цвет фона */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: block;
  box-shadow: 0 0 10px #000000;
}
.menu {
  background-color: rgba(0, 0, 0, 0.1);
  width: 100%;
  position: fixed;
  z-index: 10;
  height: 60px;
}
.logo {
  line-height: 58px;
}
.logo a {
  text-decoration: none;
  color: black;
}
.logo img {
  width: 400px;
}
.menu_wrapper {
  display: flex;
  justify-content: space-between;
  width: 1400px;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
}
.menu_href_wrapper {
  display: flex;
  line-height: 58px;
  justify-content: space-between;
  width: 500px;
  font-family: Verdana, sans-serif;
  font-size: 14px;
}
.menu_href_wrapper a {
  text-align: center;
  font-weight: 600;
  color: #d5d5d7;
  text-decoration: none;
  -webkit-transition: 0.1s ease-in;
  -o-transition: 0.1s ease-in;
  transition: 0.1s ease-in;
}
.menu_href_wrapper a:hover,
.menu_href_wrapper a:focus {
  color: rgba(195, 195, 195, 0.7);
  outline: none;
}
.menu_href_wrapper a:active {
  color: #646464;
  outline: none;
}
.menu_contacts {
  font-family: Verdana, sans-serif;
  font-size: 14px;
  line-height: 30px;
}
.menu_contacts a {
  width: 90px;
  text-align: center;
  font-weight: 600;
  color: #d5d5d7;
  text-decoration: none;
  -webkit-transition: 0.1s ease-in;
  -o-transition: 0.1s ease-in;
  transition: 0.1s ease-in;
}
.menu_contacts a:hover,
.menu_contacts a:focus {
  color: rgba(195, 195, 195, 0.7);
  outline: none;
}
.menu_contacts a:active {
  color: #646464;
  outline: none;
}
.home_about_box {
  display: flex;
  width: 450px;
  justify-content: space-between;
  margin-top: 30px;
}
.home_about_item {
  text-align: center;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.8);
}
.home_about_item img {
  width: 50px;
  height: 50px;
}
.home-heading {
  margin-top: 140px;
  color: white;
}
.home-general-heading {
  font-size: 60px;
  width: 440px;
  font-weight: 900;
  line-height: 1.2;
  font-family: Verdana, sans-serif;
}
.home-secondary-heading {
  margin-top: 15px;
  font-size: 14px;
  width: 450px;
  line-height: 1.2;
  font-family: Arial, sans-serif;
}
@media (max-width: 800px) {
  .menu {
    height: 150px;
  }
  .menu_wrapper {
    display: unset;
    text-align: center;
    width: 100%;
  }
  .menu_href_wrapper {
    display: unset;
    line-height: 10px;
  }
  .menu_href_wrapper a {
    margin-left: 20px;
  }
  .menu_contacts {
    text-align: right;
    margin-right: 10px;
  }
  .logo img {
    width: 300px;
  }
  .home_about_box {
    width: 450px;
    margin-top: 250px;
    margin-left: 50%;
    transform: translateX(-50%);
  }
  .home-secondary-heading {
    margin-top: 15px;
    width: auto;
    margin-left: 15px;
    margin-right: 15px;
  }
  .home-page .container {
    padding-top: 2px;
  }
}
@media (max-width: 450px) {
  .home-heading {
    margin-top: 140px;
  }
  .home-general-heading {
    font-size: 25px;
    margin: 15px;
    width: auto;
  }
  .menu {
    height: 120px;
  }
  .menu_contacts {
    display: none;
  }
  .logo img {
    width: 250px;
  }
  .menu_href_wrapper a {
    font-size: 12px;
  }
  .home_about_box {
    width: 320px;
    margin-top: 30px;
    margin-left: 50%;
    transform: translateX(-50%);
  }
}
.messengers_buttons {
  display: none;
  position: fixed;
  right: 15%;
  bottom: 25px;
  z-index: 10;
}
.messengers_buttons img {
  width: 45px;
  height: 45px;
}
.messengers_buttons .whatsapp_button {
  background-color: #33b446;
  width: 60px;
  height: 60px;
  margin-right: 20px;
  border-radius: 50px;
  padding: 8px;
}
.messengers_buttons .telegram_button {
  background-color: #27a7e5;
  width: 60px;
  height: 60px;
  margin-right: 50px;
  border-radius: 50px;
  padding-left: 4px;
  padding-top: 9px;
}
@media (max-width: 800px) {
  .messengers_buttons {
    display: flex;
  }
}
.about-item-box {
  display: flex;
  width: 960px;
  margin: 0 auto;
  justify-content: center;
}
.about-item {
  margin: 10px;
  text-align: center;
}
.about-item img {
  width: 300px;
  height: 300px;
}
.yelow-item {
  color: #cc9800;
}
.yelow-item .general-heading p:first-letter {
  color: #fecd04;
}
.red-item {
  color: #911825;
}
.red-item .general-heading p:first-letter {
  color: #c92132;
}
.black-item {
  color: #000000;
}
.black-item .general-heading p:first-letter {
  color: #414141;
}
.about-heading {
  position: relative;
}
.about-container {
  max-width: 1500px;
  margin-left: auto;
  margin-right: auto;
  height: 670px;
  padding-top: 40px;
}
.about-item-box-2 {
  height: 150px;
  width: 150px;
  background-color: #c92132;
  text-align: center;
  padding-top: 40px;
  margin-left: 1200px;
  box-shadow: 0 0 20px #c92132;
  position: absolute;
}
.about-item-box-2 p {
  color: white;
  font-weight: 900;
  line-height: 1.2;
  font-family: Verdana, sans-serif;
}
.about-item-box-2 span {
  font-family: Arial, sans-serif;
  font-weight: 100;
  font-size: 25px;
}
@media (max-width: 800px) {
  .about-item-box {
    display: unset;
    width: auto;
  }
  .about-item-box .general-heading {
    font-size: 20px;
  }
  .about-item-box .secondary-heading {
    font-size: 15px;
  }
  .about-item {
    margin: 10px;
    text-align: center;
  }
  .about-item img {
    width: 150px;
    height: 150px;
  }
  .about-item-box-2 {
    height: 120px;
    width: 120px;
    margin-top: 20px;
    margin-left: 50%;
    transform: translateX(-50%);
    padding-top: 25px;
  }
  .about-container {
    height: 950px;
  }
}
.services {
  background-color: #1d2025;
  color: white;
}
.service_box--wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 90px;
}
.service_flag__wrapper {
  position: relative;
}
.service_flag {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.service_flag img {
  width: 862px;
  opacity: 50%;
}
.service_box {
  width: 300px;
  background-color: #c92132;
}
.service_box a {
  text-decoration: none;
  color: white;
}
.service_box:hover .img_service_box img {
  transform: scale(0.9);
  /* Увеличиваем масштаб */
}
.img_service_box {
  width: 300px;
  height: 250px;
  display: inline-block;
  /* Строчно-блочный элемент */
  overflow: hidden;
  /* Скрываем всё за контуром */
  position: relative;
}
.img_service_box img {
  position: absolute;
  margin: auto;
  left: -100%;
  right: -100%;
  top: -100%;
  bottom: -100%;
  transition: 1s;
  /* Время эффекта */
  display: block;
  /* Убираем небольшой отступ снизу */
}
.text_box_service_box {
  padding-left: 15px;
  height: 150px;
  width: 300px;
  margin-top: -7px;
  background-color: #c92132;
  position: relative;
}
.text_box_service_box h2 {
  padding-top: 10px;
}
.btn_service_box {
  position: absolute;
  bottom: 32px;
  left: 50%;
  transform: translateX(-50%);
  border-color: white;
  border: solid;
  border-width: 1px;
  display: inline-block;
  padding: 10px;
}
#btn_service_box {
  bottom: 15px;
}
#registration {
  background-color: #373C45;
  color: white;
}
.animated-modal {
  max-width: 550px;
  border-radius: 4px;
  overflow: hidden;
  transform: translateY(-50px);
  transition: all .9s;
}
/* Final state */
.fancybox-slide--current .animated-modal {
  transform: translateY(0);
  opacity: 1;
}
#msg {
  position: fixed;
  bottom: 20px;
  z-index: 99999;
  left: 50%;
  transform: translateX(-50%);
  color: #373C45;
  border-style: solid;
  border-radius: 5px;
  border-width: 2px;
  border-color: rgba(0, 0, 0, 0);
  padding: 10px;
  transition: 2s;
  opacity: 0;
  width: 200px;
}
@media (max-width: 800px) {
  .services {
    height: 1900px;
  }
  .service_box--wrapper {
    display: unset;
    position: absolute;
    margin-top: 10px;
    margin-left: 50%;
    transform: translateX(-50%);
  }
  .service_box {
    margin-bottom: 20px;
  }
  .service_flag__wrapper {
    display: none;
  }
}
.form-inner {
  padding: 5px;
  line-height: 5px;
}
.form-inner input,
.form-inner textarea {
  display: block;
  width: 100%;
  padding: 0 20px;
  margin-bottom: 25px;
  background: #E9EFF6;
  line-height: 30px;
  border-width: 0;
  border-radius: 20px;
  font-family: 'Roboto', sans-serif;
}
.form-inner input[type="submit"] {
  margin-top: 20px;
  background: #f69a73;
  border-bottom: 4px;
  color: white;
  font-size: 14px;
}
#submit:hover,
#submit:focus {
  background-color: rgba(180, 180, 180, 0.7);
  outline: none;
}
#submit:active {
  background-color: #646464;
  outline: none;
}
.form-inner textarea {
  resize: none;
}
.form-inner h3 {
  margin-top: 0;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 24px;
  color: #707981;
}
.price {
  background-image: url("../../img/bkg/bg.jpg");
  background-size: cover;
  background-attachment: fixed;
  color: white;
}
.price .container {
  height: 800px;
}
.prices-controls {
  display: flex;
}
.page-number_prices-controls {
  display: flex;
}
.prices__wrapper {
  position: relative;
  height: 500px;
}
.prices-box {
  width: 1000px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
@media (max-width: 999px) {
  .prices-box {
    width: 650px;
  }
}
@media (max-width: 500px) {
  .prices-box {
    width: 300px;
  }
}
.price_box {
  background-color: white;
  box-shadow: 0 0 20px 1px #3c3c3c;
  display: inline-block;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 300px;
  height: 450px;
}
.prices-controls__wrapper {
  position: relative;
}
.prices-controls {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateY(-50%);
  transform: translateX(-50%);
}
.prices-controls a {
  text-decoration: none;
  color: white;
}
.left-control_prices-controls {
  border: solid;
  border-width: 1px;
  display: inline-block;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 12px;
  padding-right: 12px;
  margin-right: 20px;
}
.right-control_prices-controls {
  border: solid;
  border-width: 1px;
  display: inline-block;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 12px;
  padding-right: 12px;
  margin-left: 20px;
}
.page-number_prices-controls {
  padding: 5px;
}
.price-text {
  text-align: center;
  color: #000;
  font-weight: 300;
  line-height: 1.2;
  font-family: Arial, sans-serif;
  font-size: 18px;
  border-bottom: solid;
  border-width: 2px;
  border-color: #c91e31;
}
.unit-price {
  text-align: center;
  color: rgba(0, 0, 0, 0.9);
  font-weight: 300;
  line-height: 1.2;
  font-family: Arial, sans-serif;
  font-size: 18px;
  margin-top: 50px;
}
.unit-price-numb {
  color: rgba(201, 30, 49, 0.9);
  font-weight: 600;
}
.price_box {
  padding-right: 30px;
  padding-left: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
  position: relative;
}
.price_box a {
  color: white;
  font-weight: 500;
  line-height: 1.2;
  font-family: Verdana, sans-serif;
  text-decoration: none;
}
.btn_price_box {
  margin-top: 30px;
  background-color: #c92132;
  text-align: center;
  box-shadow: 0 0 20px #c92132;
  position: absolute;
  width: 150px;
  height: 50px;
  left: 50%;
  transform: translateX(-50%);
  padding-top: 15px;
  border-radius: 20px;
}
.stock {
  text-align: center;
  width: auto;
  background-color: #c92132;
  height: 90px;
  line-height: 0.9em;
  padding-top: 15px;
  line-height: 1.2;
}
.contact {
  background-color: #1d2025;
  color: white;
}
.contact .container {
  height: 800px;
}
.contact-item-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.contacts-text_border {
  height: 447px;
  width: 667px;
  border-color: #c91e31;
  border-style: solid;
  border-width: 2px;
}
.contacts-text {
  margin-top: 50%;
  transform: translateY(-70%);
  margin-left: 50px;
  display: flex;
  flex-wrap: wrap;
  width: 450px;
}
.contacts-text div {
  margin-bottom: 50px;
}
.contacts-text a {
  color: white;
  text-decoration: none;
  -webkit-transition: 0.1s ease-in;
  -o-transition: 0.1s ease-in;
  transition: 0.1s ease-in;
}
.contacts-text a:hover,
.contacts-text a:focus {
  color: #c91e31;
  outline: none;
}
.contacts-text a:active {
  color: #CB3A44;
  outline: none;
}
.contacts-text_social-media img:hover,
.contacts-text_social-media img:focus {
  transform: scale(1.3);
  /* Увеличиваем масштаб */
}
.contacts-text_social-media img:active {
  transform: scale(1);
  /* Увеличиваем масштаб */
}
.contacts-text_phone,
.contacts-text_social-media {
  margin-left: 72px;
}
.contacts-map_big {
  display: block;
}
.contacts-map_little {
  display: none;
}
.social_web_block img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  transition: .5s;
}
@media (max-width: 800px) {
  .contacts-text_border {
    height: 100px;
    width: 300px;
    border-style: none;
  }
  .contacts-text {
    top: 50%;
    transform: translateY(-35%);
  }
  .contacts-map_big {
    display: none;
  }
  .contacts-map_little {
    margin-top: 150px;
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  .contact .container {
    height: 800px;
  }
  .contact-item-box {
    display: block;
    align-items: center;
    justify-content: normal;
  }
  .contacts-text {
    width: 300px;
  }
  .contacts-text div {
    margin-bottom: 10px;
  }
  .contacts-text_phone,
  .contacts-text_social-media {
    margin-left: 0px;
  }
}
.parts {
  background-color: #1d2025;
  color: white;
}
.parts-item-box {
  display: flex;
  margin: 40px;
  font-size: 150%;
  height: 350px;
  text-align: center;
}
.parts-item-box div {
  margin: 20px;
}
.parts-new {
  background-image: url("../../img/bkg/new box.png");
  background-size: cover;
  height: 550px;
  display: flex;
  align-items: center ;
}
.parts-old {
  background-image: url("../../img/bkg/furgon.png");
  background-size: cover;
  height: 550px;
  display: flex;
  align-items: center;
}
@media (max-width: 800px) {
  .parts {
    height: 1000px;
  }
  .parts-item-box {
    display: unset;
  }
  .parts-new,
  .parts-old {
    height: 350px;
  }
}
.questions {
  background-image: url("../../img/bkg/ODocOQFdUo3m71i_2.png");
  color: white;
  background-size: cover;
}
.questions-box {
  margin-top: 150px;
  margin-bottom: 50px;
}
.accordion {
  transform: translateZ(0);
  background: #1d2025;
  border-bottom: 1px solid #c91e31;
  border-left: 1px solid #c91e31;
  border-right: 1px solid #c91e31;
}
.accordion > .accordion-toggle {
  display: none;
  position: absolute;
  opacity: 0;
}
.accordion > label {
  display: block;
  position: relative;
  height: 50px;
  line-height: 50px;
  padding: 0 20px;
  border-top: 1px solid #c91e31;
  border-bottom: 1px solid #c91e31;
  background: #1d2025;
  color: white;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
}
.accordion > label:after {
  content: '';
  position: absolute;
  top: 12px;
  right: 20px;
  width: 12px;
  height: 12px;
  border-left: 2px solid #c91e31;
  border-top: 2px solid #c91e31;
  transform: rotate(-135deg);
  transition: 0.4s transform;
}
.accordion > section {
  overflow: hidden;
  height: 0;
  transition: all .4s;
}
.accordion > .accordion-toggle:checked ~ label:after {
  transform: rotate(45deg);
  top: 16px;
}
.accordion > .accordion-toggle:checked ~ section {
  height: 100px;
}
.accordion > section p {
  margin: 15px 0;
  padding: 0 20px;
}
.mobile_visible_qestion {
  display: none;
}
@media (max-width: 450px) {
  .mobile_hide_qestion {
    display: none;
  }
  .mobile_visible_qestion {
    display: inline;
  }
  .accordion > .accordion-toggle:checked ~ section {
    height: 200px;
  }
}
.footer {
  text-align: center;
  background-color: black;
  color: white;
  padding-bottom: 25px;
}
.footer .container {
  height: auto;
}
