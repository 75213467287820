.about-item-box {
	display: flex;
	width: 960px;
	margin:0 auto;
	//flex-wrap: wrap; //перенос на новую строку
	justify-content: center; //центрируем 
	//padding-top: 50px;

}

.about-item {
	//display: flex;
	margin: 10px;
	text-align: center;

	img {
		width: 300px;
		height: 300px;
	}

	div {
		//margin-top: 20px;
		//margin-left: 5px;
		//width: 300px;
		
	}
}

.yelow-item {
	color: #cc9800;
	.general-heading {
	p:first-letter {
		color: #fecd04;
	} }
}

.red-item {
	color: #911825;
	.general-heading {
	p:first-letter {
		color: #c92132;
	} }
}

.black-item {
	color: #000000;
	.general-heading {
	p:first-letter {
		color: #414141;
	} }
}
.about-heading {
	position: relative;
}

.about-container {
	max-width: 1500px;
	margin-left: auto;
	margin-right: auto;
	height: 670px;
	padding-top: 40px;
}

.about-item-box-2 {
	height: 150px;
	width: 150px;
	background-color: #c92132;
	text-align: center;
	padding-top: 40px;
	margin-left: 1200px;
	box-shadow: 0 0 20px #c92132;
	position: absolute;
	p{
		color: white;
		font-weight: 900;
  		line-height: 1.2;
  		font-family: Verdana, sans-serif;
	}
	span{
		font-family: Arial, sans-serif;
		font-weight: 100;
		font-size: 25px;
	}
}


@media (max-width: 800px) {

.about-item-box {
	display: unset;
	width: auto;
	.general-heading{
		font-size: 20px;
	}
	.secondary-heading {
		font-size: 15px;
	}
}

.about-item {
	//display: flex;
	margin: 10px;
	text-align: center;

	img {
		width: 150px;
		height: 150px;
	}
}

.about-item-box-2 {
	height: 120px;
	width: 120px;
	margin-top: 20px;
	margin-left: 50%;
	transform: translateX(-50%);
	padding-top: 25px;
}
.about-container {
	height: 950px;
}

}