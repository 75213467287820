.price {

background-image: url("../../img/bkg/bg.jpg");
background-size: cover;
background-attachment: fixed;
color: white;

	.container {
		height: 800px;
	}

}



.prices-controls {
	display: flex;
}

.page-number_prices-controls {
	display: flex;
}

.prices__wrapper {
	position: relative;
	height: 500px;
}
.prices-box {

	width: 1000px;
	position: absolute;
	left: 50%;
	transform:translateX(-50%);
}

@media (max-width: 999px) {
.prices-box {
width: 650px;
}
}

@media (max-width: 500px) {
.prices-box {
width: 300px;
}
}



.price_box {
	background-color: white;
	box-shadow: 0 0 20px 1px rgba(60,60,60,1);

	display: inline-block;
	margin-top: 20px;
	margin-bottom: 20px;
	width: 300px;
	height: 450px;
}
.prices-controls__wrapper {
	position: relative;

}

.prices-controls {
	position: absolute;
	left:50%;
	top:50%;
	transform: translateY(-50%);
	transform: translateX(-50%);
	a {
		text-decoration: none;
  color: white;
	}
}

.left-control_prices-controls {
 	border: solid;
	border-width: 1px;
	display: inline-block;
	padding-top: 5px;
	padding-bottom: 5px;
	padding-left: 12px;
	padding-right: 12px;
	margin-right: 20px;
}

.right-control_prices-controls {
 	border: solid;
	border-width: 1px;
	display: inline-block;
	padding-top: 5px;
	padding-bottom: 5px;
	padding-left: 12px;
	padding-right: 12px;
	margin-left: 20px;
}

.page-number_prices-controls {
	padding: 5px;
}

.price-text {
text-align: center;
	color: #000;
	font-weight: 300;
	line-height: 1.2;
	font-family: Arial, sans-serif;
	font-size: 18px;

	border-bottom: solid;
	border-width: 2px;
	border-color: #c91e31;
	
}

.unit-price {
	text-align: center;
	color: rgba(0,0,0,.9);
	font-weight: 300;
	line-height: 1.2;
	font-family: Arial, sans-serif;
	font-size: 18px;
	margin-top: 50px;
	
}
.unit-price-numb {
	color: rgba(201,30,49,.9);
	font-weight: 600;
}

.price_box {
	padding-right: 30px;
	padding-left: 30px;
	padding-top: 30px;
	padding-bottom: 30px;
	position: relative;
	a{
		color: white;
		font-weight: 500;
  		line-height: 1.2;
  		font-family: Verdana, sans-serif;
  		text-decoration: none;
 	}

}


.btn_price_box {
	margin-top: 30px;

background-color: #c92132;
	text-align: center;
	box-shadow: 0 0 20px #c92132;
	position: absolute;
	width: 150px;
	height: 50px;
	left: 50%;
	transform: translateX(-50%);
	padding-top: 15px;
	border-radius: 20px;
	

}

.stock {

	//margin-top: 65px;
	text-align: center;
	width: auto;
	background-color: #c92132;
	//margin-bottom: 200px;
	height: 90px;
	line-height: 0.9em;
	padding-top: 15px;
	line-height: 1.2;

}