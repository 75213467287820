.parts {

	background-color: #1d2025;
	color: white;


	.container {
		//height: 600px;
	}
}


.parts-item-box {
	display: flex;
	margin:40px;
	font-size: 150%;
	height: 350px;
	div{
		margin: 20px;
	}
	//color: black;
	text-align: center;

}

.parts-new {
background-image: url("../../img/bkg/new box.png");
background-size: cover;

height: 550px;

display: flex;
  align-items: center ;
}

.parts-old {
background-image: url("../../img/bkg/furgon.png");
background-size: cover;

height: 550px;
display: flex;
  align-items: center 
}


@media (max-width: 800px) {

.parts {
	height: 1000px;

}

.parts-item-box {
	display: unset;
}

.parts-new,.parts-old {
height: 350px;
}
}