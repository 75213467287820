.cookie_warning{
  position: fixed;
  bottom: 15px;
  left:50px;
  width: 350px;
  font-size: 70%;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0,0,0,0.5);
  z-index: 5;
  background-color: #1d2025;
  padding: 10px;
  visibility: hidden;
  color: white;
  }
  @media (max-width: 940px) {
  .cookie_warning {
    bottom: 10px;
    left:5px;
    width: 150px;
    z-index: 9999999;
  } }


.cookie_btn_text {
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
 margin-top: 5px;
  border-radius: 5px;
  border-style: solid;
  border-width: 2.5px;
  width: 100px;
  height: 25px;
  color: #c91e31;
  position: relative;
  background-color: #c91e31;
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
}
.cookie_btn_text p {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  font-size: 12px;
  font-family: 'Open sans', sans-serif;
  font-weight: 600;
  text-align: center;
  color: #ffffff;
}
.cookie_btn_text:hover,
.cookie_btn_text:focus {
  background-color: #DD263A;
  color: #DD263A;
  outline: none;
}
.cookie_btn_text:active {
  background-color: #6C0E18;
  color: #6C0E18;
}